<template>
  <section class="invoice-preview-wrapper">
    <div class="alert-show" v-if="showMessage">
      <b-alert
        variant="danger"
        :show="showMessage"
      >
        <h4 class="alert-heading">
          No se puede obtener los datos de la transacción
        </h4>
        <div class="alert-body">
          No se encontró ninguna transacción.
          <b-link
            v-if="userData.id"
            class="alert-link"
            :to="{ name: 'Profile' }"
          >
            Consulte la lista de transacciones
          </b-link>
        </div>
      </b-alert>
    </div>
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <feather-icon
            icon="ClockIcon"
            size="24"
          />
          <p id="cancel-label">
            Pronto se mostrara aqui el estado de tu transacción
          </p>
        </div>
      </template>
      <b-row
        v-if="Object.keys(paymentResult).length > 0"
        class="invoice-preview pb-0"
      >
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                  </div>
                  <p class="card-text mb-25">
                    Carrera 98B Sur 69-06 casa 47 Conjunto Kasay 1
                  </p>
                  <p class="card-text mb-25">
                    Bogotá, Colombia
                  </p>
                  <p class="card-text mb-0">
                    contacto@travelbus.com.co
                  </p>
                  <p class="card-text mb-0">
                    +(57) 3118080225
                  </p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    Transacción
                    <span class="invoice-number">#{{ paymentResult.id_transaction }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      Fecha de Creación:
                    </p>
                    <p class="invoice-date">
                      {{ paymentResult.created_at.split('T')[0] }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      Estado:
                    </p>
                    <p class="invoice-date">
                      <b-badge
                        pill
                        :variant="getStatus(paymentResult.status_transaction)"
                      >
                        {{ getName(paymentResult.status_transaction) }}
                      </b-badge>
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>
            <!-- Spacer -->
            <hr class="invoice-spacing">
            <b-card-body
              v-if="paymentResult"
              class="invoice-padding pt-0"
            >
              <b-row
                v-if="travelData"
                class="invoice-spacing"
              >
                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="p-0"
                >
                  <h6 class="mb-2">
                    Facturar a:
                  </h6>
                  <h6 class="mb-25">
                    {{ travelData.chairs_select[0].first_name }} {{ travelData.chairs_select[0].last_name }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ travelData.chairs_select[0].telefono }}
                  </p>
                  <p class="card-text mb-0">
                    {{ travelData.chairs_select[0].email }}
                  </p>
                </b-col>
                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6 class="mb-2">
                      Detalles del pago:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Total:
                          </td>
                          <td><span class="font-weight-bolder">{{ formatPrice(travelData.total_price) }}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Tipo de pago:
                          </td>
                          <td>{{ paymentResult.payment_method }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Tarjeta:
                          </td>
                          <td>{{ paymentResult.payment_method_card }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Invoice Description: Table -->
            <b-table-lite
              v-if="travelData"
              responsive
              :items="travelData.chairs_select"
              :fields="fields"
            />
            <hr>
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="12"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bolder">Detalles del viaje</span>
                    <vue-detail
                      v-if="travelData"
                      :travel-data="travelData"
                      title="Salida/Llegada"
                    />
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card-body>

            <hr>
            <b-card-body
              v-if="travelData"
              class="invoice-padding pb-0"
            >
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bolder">Tipo de Autobús:</span>
                    <span class="ml-75">{{ travelData.type_bus }}</span>
                  </b-card-text>
                </b-col>
                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Tiquete:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{ travelData.total_price }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Cargo por transacción:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{ travelData.cost_per_transaction }}
                      </p>
                    </div>

                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Cargo por metodo de pago:
                      </p>
                      <p class="invoice-total-amount">
                        Porcentaje <b>{{ travelData.type_payment.transaction_fee }}</b>  %
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Descuento:
                      </p>
                      <p class="invoice-total-amount">
                        $0
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{ totalPrice({
                          total_price: parseFloat(travelData.total_price),
                          transaction_fee: parseFloat(travelData.cost_per_transaction || 0),
                          transaction_fee_type_payment: parseFloat(travelData.type_payment.transaction_fee || 0)
                        }) }}
                      </p>
                    </div>
                    <!-- <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      IVA:
                    </p>
                    <p class="invoice-total-amount">
                      19%
                    </p>
                  </div> -->
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{ totalPrice({
                          total_price: parseFloat(travelData.total_price),
                          transaction_fee: parseFloat(travelData.cost_per_transaction || 0),
                          transaction_fee_type_payment: parseFloat(travelData.type_payment.transaction_fee || 0)
                        }) }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Nota: </span>
              <span>Recuerda que si tu compra se encuentra en un estado diferente a APROBADO no hemos descontado ningun
                dinero de tu medio de pago </span>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card>
            <!-- Button: Print -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              @click="printInvoice"
            >
              Imprimir
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>
<script>
import {
  BCard, BCardBody, BRow, BCol, BOverlay, BAlert, BLink,
  BTableLite, BCardText, BButton, BBadge,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import DetailReserva from '@/components/Payments/DetailReserva.vue'

export default {
  name: 'PaymentResultComponent',
  components: {
    BOverlay,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BAlert,
    BLink,
    BTableLite,
    BCardText,
    BButton,
    BBadge,

    Logo,
    'vue-detail': DetailReserva,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      paymentResult: {},
      travelData: null,
      showOverlay: false,
      showMessage: false,
      fields: [
        {
          key: 'first_name', label: 'Nombres',
        },
        {
          key: 'last_name', label: 'Apellidos',
        },
        {
          key: 'document', label: 'Documento',
        },
        {
          key: 'label', label: 'Silla',
        },
        {
          key: 'importe_base',
          label: 'Precio',
          formatter: value => `$ ${this.formatPrice(value)}`,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ userData: 'userApp/getProfileData' }),
  },
  async mounted() {
    this.showOverlay = true
    this.showMessage = false
    this.paymentResult = await this.getTransactionResult()
    if (Object.keys(this.paymentResult).length > 0) {
      this.travelData = await this.getTransaction(this.paymentResult.reference.split('-')[2])
    } else {
      this.showMessage = true
    }
    this.showOverlay = false
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat('de-DE',
        {
          currency: 'COP',
        }).format(price)
    },
    getTransactionResult() {
      const result = this.$http.get(`/v1/payment-result/${this.$route.params.payment_id}`)
        .then(res => res.data)
      return result
    },
    getTransaction(paymentId) {
      const result = this.$http.get(`/v1/soap/transaction/${paymentId}`)
        .then(res => res.data.data)
      return result
    },
    printInvoice() {
      window.print()
    },
    getStatus(value) {
      const statusColor = {
        /* eslint-disable key-spacing */
        ACTIVADO      : 'success',
        DESACTIVADO : 'danger',
        BLOQUEADO     : 'danger',
        PENDIENTE     : 'warning',
        DECLINADO      : 'warning',
        APROBADO      : 'success',
        ANULADO      : 'danger',
        ERROR      : 'danger',
        /* eslint-enable key-spacing */
      }
      return statusColor[value]
    },
    getName(value) {
      const name = {
        /* eslint-disable key-spacing */
        ACTIVADO      : 'ACTIVADO',
        DESACTIVADO : 'DESACTIVADO',
        BLOQUEADO     : 'BLOQUEADO',
        APPROVED: 'APROBADO',
        DECLINED: 'DECLINADO',
        VOIDED: 'ANULADO',
        ERROR: 'ERROR',
        PENDING: 'PENDIENTE',
        /* eslint-enable key-spacing */
      }
      return name[value]
    },
    totalPrice(total) {
      const percent = total.transaction_fee_type_payment / 100
      let totalSum = total.total_price + total.transaction_fee
      const percentTotal = totalSum * percent
      if (percent !== 0) {
        totalSum += percentTotal
      }
      this.$emit('total_sum', parseFloat(totalSum).toFixed(0))
      return parseFloat(totalSum).toFixed(0)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .horizontal-menu-wrapper {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .btn-scroll-to-top {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  .alert-show {
    display: none;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            >.col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
